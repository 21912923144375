import React, { useEffect } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { FaMapMarker,FaPhone,FaVoicemail,FaMailBulk } from 'react-icons/fa';


const containerStyle = {
    width: '100%',
    height: '400px'
  };
    
  const center = {
    lat: 51.6611,
    lng: 0.3970
  };
  

const Contact = () => {
    useEffect(() => {
        window.scrollTo({
         top: 0,
         behavior: 'smooth' // This adds smooth scrolling animation (optional)
     });
   }, []);
    return (
        <section className="contact spad padding-header">
           {/* <LoadScript googleMapsApiKey="YOUR_API_KEY">
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={10}
            >
                <Marker position={center} />
            </GoogleMap>
            </LoadScript> */}

            <div className="container">
                <div className="contact map">
                    <div className="container">
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-4 col-md-7">
                                <div className="map__inner">
                                    <h6>Moj Masala</h6>
                                    <ul > 
                                        <li>755, Harrow Road,Wembley, HA0 2LW</li>
                                        <li>mojmasala07@gmail.com</li>
                                        <li>020 3509 3261</li>
                                    </ul>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div className="map__iframe">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d10784.188505644011!2d19.053119335158936!3d47.48899529453826!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1543907528304" height="300" title='map' className='mapdiv'></iframe>
                    </div>
                </div>
                <div className="contact__address">
                    <div className="row gap">
                        <div className="col-md-6 col-12">
                            <div className="contact__address__item">
                                <h6>Small bite</h6>
                                <ul>
                                    <li>
                                        <span><FaMapMarker/></span>
                                        <p>Bhakti Nagar Cir, Lakshmiwadi, Bhakti Nagar,<br/> Rajkot, Gujarat 360002, India</p>
                                    </li>
                                    <li><span><FaPhone/></span>
                                        <p>+91 99042 28228</p>
                                    </li>
                                    <li><span><FaMailBulk/></span>
                                        <p>smallbite@gmail.com</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <div className="contact__address__item">
                                <h6>Akshar Catering</h6>
                                <ul>
                                    <li>
                                        <span><FaMapMarker/></span>
                                        <p>Madhav Park<br/> Rajkot, Gujarat 360002, India</p>
                                    </li>
                                    <li><span><FaPhone/></span>
                                        <p>+91 89800 59682</p>
                                    </li>
                                    <li><span><FaMailBulk/></span>
                                        <p>aksharCatering@gmail.com</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                       
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="contact__text contact__address__item">
                            <h6>Contact With us</h6>
                            <ul>
                                <li>Representatives or Advisors are available:</li>
                                <li>Mon-Fri: 9:00am to 5:00pm</li>
                                <li>Sat-Sun: 9:00am to 1:00pm</li>
                            </ul>
                            <img src="img/cake-piece.png" alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="contact__form">
                            <form action="#">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <input type="text" placeholder="Name"/>
                                    </div>
                                    <div className="col-lg-6">
                                        <input type="text" placeholder="Email"/>
                                    </div>
                                    <div className="col-lg-12">
                                        <textarea placeholder="Message"></textarea>
                                        <button className="site-btn">Send Us</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
                
    );
}
  
export default Contact;

