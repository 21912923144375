import React, { useState } from 'react';
import arrowdown from "../assets/arrowdown.svg"

const ExpansionPanel = ({ title, content }) => {
  const [expanded, setExpanded] = useState(false);

  const togglePanel = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
        <div class="container">
       
            <div className={`expansion-panel ${expanded ? 'expanded' : ''}`}>
            <div className="expansion-panel-header" onClick={togglePanel}>
            {title}
            <img src={arrowdown} alt="notfound" className='arrowdown arrow-right' />
            </div>
            {expanded && (
           <div className="expansion-panel-content" dangerouslySetInnerHTML={{ __html: content }}>
            
            </div>
            )}
     
            </div>
        </div>
    </div>
  
  );
};

export default ExpansionPanel;
