import React from 'react';
import { FaClock, FaFacebookSquare, FaInstagramSquare } from 'react-icons/fa';


const Footer = () => {
    return (
        <div class="copyright">
        <div class="container">
            <div class="row">
                <div class="col-9">
                    <p class="copyright__text text-white">
                    © Copyright Moj Masala 2023. All rights reserved.
                    </p>
              </div>
              <div class="col-3">
                <div class="copyright__widget">
                    <span className='pr-1'>
                        <a className='footerlink' href='https://www.facebook.com/profile.php?id=61550959761982'>
                         <FaFacebookSquare />
                         </a>
                    </span>
                        <a className='footerlink' href='https://instagram.com/mojmasala_uk?igshid=MzMyNGUyNmU2YQ=='>
                            <FaInstagramSquare />
                        </a>
                </div>
             </div>
           </div>
       </div>
    </div>
    )
} 

export default Footer