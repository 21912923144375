import React, { useEffect } from 'react';
import ExpansionPanel from './expansionPanel';

const Menu = () => {
    useEffect(() => {
           window.scrollTo({
            top: 0,
            behavior: 'smooth' // This adds smooth scrolling animation (optional)
        });
      }, []);

    
    const breakfast = `<p><span classname="menudots">Masala Chai</span> .......................................... £2.00 
                       <br/>Fafda Gathiya Kg .................................. £18.00 
                       <br/>Gathiya Plate(fafda/vanela,jalebi,red chatni, kadhi) . £6.99 
                       <br/>Vanela Gathiya kg ................................ £18.00 
                       <br/>Jalebi 1 kg ............................................ £15.00 
                       <br/>Naylon Khaman .................................. £3.99 
                       <br/>Sev Khamni ......................................... £4.99 
                       <br/>Thepla.................................................... £2.00
                     </p>`;

    const chaatCorner = `<p>PaniPuri(7 Pcs).................................. £2.00 
                       <br/>Dahi Puri  ........................................... £3.99 
                       <br/>Sev Puri  ............................................. £3.99 
                       <br/>Sp. Bhel.............................................. £5.49 
                       <br/>Punjabi Samosa (2 Pcs) ........................... £3.00
                       <br/>Samosa chaat .................................... £4.49 
                       <br/>Aloo Puri ........................................... £3.00
                       <br/>Cheese aloo puri ................................ £4.00
                      </p>`;

    const EveryThingInBetween = `<p>Vada Pav............................................................ £2.50 
                       <br/>Cheese Vadapav  ............................................... £3.50 
                       <br/>Samosa Pav ...................................................... £3.00 
                       <br/>Dabeli ............................................................... £3.50
                       <br/>Cheese Dabeli .................................................... £4.00 
                       <br/>Sp. Kutchhi Dabeli .............................................. £4.50
                        </p>`;

    const Sandwhich = `<p>Bombay Sandwich........................................... £3.50 
                       <br/>Bombay Sandwich with cheese  ...................... £4.75 
                       <br/>Veg. Cheese Grill Sandwich ........................... £4.99 
                       <br/>Moj Masala Sp. Jungli Sandwich ................... £6.49 
                       </p>`;

    const SevUsal = `<p>Sev Usal ....................................................... £6.00 
                       <br/>Cheese Sev UsaL  ......................................... £7.50 
                       <br/>Sev Tari ....................................................... £6.50 
                       <br/>Cheese Sev Tari............................................ £8.00
                       <br/>Extra pav ..................................................... £1.50 
                       <br/>Extra Raso ................................................... £2.00 
                       <br/>Extra sev ..................................................... £2.00
                       </p>`;

    const Maggi = `<p>Masala Maggi ............................................. £2.50 
                       <br/>Cheese Masala Maggi ................................. £3.00 
                       <br/>Vegetable Maggi ......................................... £3.50 
                       <br/>Cheese Vegetable Maggi ............................. £4.00 
                       <br/>Moj Tadka Maggi ........................................ £4.99          
                       </p>`;

    const KatakBatak = `<p>Salted French Fries ........................................ £2.99
                       <br/>Peri Peri French Fries .................................... £3.50 
                        </p>`;
    
    const Pavbhaji = `<p>Pavbhaji ....................................................... £6.49
                       <br/>Butter PavBhaji ........................................... £7.49 
                       <br/>Cheese Butter PavBhaji ............................... £8.49 
                       <br/>Extra Pav ..................................................... £2.00       
                       </p>`;

    const Curries = `<p>Paneer Butter Masala ................................. £7.49
                       <br/>Paneer Bhurji .............................................. £7.99 
                       <br/>Paneer Angara ............................................ £7.99 
                       <br/>Kaju Curry ..................................................... £8.49       
                       <br/>Cheese Butter Masala ................................. £8.99   
                       </p>`;

    const RotiParatha = `<p>Paratha ....................................................... £2.00
                       <br/>Roti (fulka) 2 in portion ............................. £1.50   
                       <br/>Butter Roti(fulka) ....................................... £2.00   
                       </p>`;

    const Ricedaal = `<p>Plain Rice ................................................... £3.00
                       <br/>Jeera Rice .................................................. £3.99 
                       <br/>Veg. Pulav .................................................. £5.49 
                       <br/>Bhaji Pulav	................................................. £5.49
                       <br/>Daal Fry ..................................................... £5.99       
                       <br/>Daal Tadka ................................................. £6.99      
                       </p>`;

    const Extra = `<p>Rosted Papad ............................................ £1.00 
                       <br/>Masala Papad ............................................ £2.49 
                       <br/>Masala Chaas	............................................. £2.49       
                       <br/>Dahi ........................................................... £1.00 
                       <br/>Chutney ........................................................ £1.00   
                       <br/>Extra cheese ............................................... £1.50   
                       </p>`;
                       
    return (
        <div>
             <ExpansionPanel
                title="Breakfast"
                content={breakfast}
            />
            <ExpansionPanel
                title="Chaat Corner"
                content={chaatCorner}
            />
             <ExpansionPanel
                title="EveryThing In-Between"
                content={EveryThingInBetween}
            />
            <ExpansionPanel
                title="Sandwhich"
                content={Sandwhich}
            />
            <ExpansionPanel
                title="Sev-Usal"
                content={SevUsal}
            />
              <ExpansionPanel
                title="Maggi"
                content={Maggi}
            />
               <ExpansionPanel
                title="Katak Batak"
                content={KatakBatak}
            />
               <ExpansionPanel
                title="Pavbhaji"
                content={Pavbhaji}
            />
               <ExpansionPanel
                title="Curries"
                content={Curries}
            />
               <ExpansionPanel
                title="Roti/Paratha"
                content={RotiParatha}
            />
               <ExpansionPanel
                title="Rice-daal"
                content={Ricedaal}
            />
            <ExpansionPanel
                title="Extra"
                content={Extra}
            />
            
        </div>
    )
}
  
export default Menu;