
import './App.css';
import Jalebi from './assets/Jalebi.jpg'
import fafdaJalebi from './assets/fafda-Jalebi.jpg'
import khaman from './assets/Khaman.png'
import homeeventicon from './assets/homeeventicon.png'
import officeeventicon from './assets/officeeventicon.png'
import partiesicon from './assets/partiesicon.png'
import takeaway from './assets/takeaway.png'
import hand from './assets/hand.png'
import mask from './assets/mask.png'
import plates from './assets/plates.png'
import sevUsal from './assets/Sevusal.jpeg'
import { Link } from 'react-router-dom';


const Home = () => {

  return (
    <div className="App">
      <div className='container'>
        <div className='home'>
          <h3 className='pt-4 foodtitle'>
            FRIENDSHIP OF FAFDA AND JALEBI
          </h3>
          <span className="fafda_desktop_menu">
            <div className="row pt-4 ">
                <div className='row col-9 pt-1 border-bottom-after'>
                    <div className='col-6'>
                      <img src={Jalebi} alt='notfound' className='w-full image-border' />
                    </div>
                    <div className='col-6 justify'>
                    <p className=''>
                      The friendship of Fafda and Jalebi is a delectable tale of contrasts coming together to create a harmonious bond on a plate. Like two inseparable friends from different backgrounds, they complete each other, offering a delightful culinary experience that is cherished by food enthusiasts across India.
                    </p>
                    <p className='pt-2'>
                    Their friednship is a harmonious blend of flavors and textures - the savory and crunchy Fafda complements the sweet and syrupy Jalebi, creating a contrast that is both satisfying and delicious.
                    </p>
                    <p>
                    In Gujarat, it's a common practice to pair Fafda and Jalebi during special occasions and festivals. This combination symbolizes the coming together of opposites to celebrate life's diversity and richness. Friends gather, share stories, and savor these delightful treats, strengthening their bonds just as Fafda and Jalebi do on the plate.
                    </p>
                  </div>
                  </div>
                <div className='col-3 justify'>
                  <p>
                  This pairing is often enjoyed as a breakfast or snack item, offering a delightful culinary experience that is both traditional and beloved by people of all ages in India.
                  </p>
                  <img src={fafdaJalebi} alt='notfound' className='w-full image-border' />
                </div>
            </div> 
          </span>
          <span className="fafda_mobile_menu">
            <div className="row pt-3">
                  <div className='col-md-6 col-12 justify'>
                    <img src={Jalebi} alt='notfound' className='w-full image-border ' />
                    <p className='pt-2'>
                    The friendship of Fafda and Jalebi is a delectable tale of contrasts coming together to create a harmonious bond on a plate. Like two inseparable friends from different backgrounds, they complete each other, offering a delightful culinary experience that is cherished by food enthusiasts across India.
                    </p>
                    <p>
                    Their friednship is a harmonious blend of flavors and textures - the savory and crunchy Fafda complements the sweet and syrupy Jalebi, creating a contrast that is both satisfying and delicious.
                    </p>
                    <p className='mt-3'>
                      This pairing is often enjoyed as a breakfast or snack item, offering a delightful culinary experience that is both traditional and beloved by people of all ages in India.
                    </p>
                  </div>
                  <div className='col-md-6 col-12 justify'>
                  <img src={fafdaJalebi} alt='notfound' className='w-full image-border h-80' />
                   
                    <p className='pt-2'> 
                    In Gujarat, it's a common practice to pair Fafda and Jalebi during special occasions and festivals. This combination symbolizes the coming together of opposites to celebrate life's diversity and richness. Friends gather, share stories, and savor these delightful treats, strengthening their bonds just as Fafda and Jalebi do on the plate.
                    </p>
                   
               
                </div>
                <hr></hr>
            </div>
           
          </span>
        </div>

        <div className='catering_border'></div>




        <div className='row border-bottom-after gap mt-40' >
        <div className='col-lg-4 col-md-6 col-12'>
            <div className='o-border--diamond p-2'>
              <h3>
              CATERING
              </h3>
              <div className='row p-4'>
                <img src={homeeventicon} alt='notfound' className='col-4 h-55' />
                <img src={officeeventicon} alt='notfound' className='col-4 h-55' />
                <img src={partiesicon} alt='notfound' className='col-4 h-55' />
              </div>
                <p className='col-10 mx-auto'>
                We can offer catering
                to your home, office or any other Events.Food is cooked on-site
                and delivered immediately
                to your location
                </p>
            </div>
        </div>
        <div className='col-lg-4 col-md-6 col-12'>
            <div className='o-border--diamond p-2'>
              <h3>
              KEEPING YOU SAFE
              </h3>
              <div className='row p-4'>
                <img src={hand} alt='notfound' className='col-4 h-55' />
                <img src={mask} alt='notfound' className='col-4 h-55' />
                <img src={plates} alt='notfound' className='col-4 h-55' />
              </div>
                <p className='col-10 mx-auto'>
                We care a lot about the safety and hygiene of our employees and customers. Here at Moj Masala we follow the government's regulations to ensure safety.
                </p>
            </div>
        </div>
        <div className='col-lg-4 col-md-6 col-12'>
            <div className='o-border--diamond p-2'>
              <h3>
              TAKE AWAY
              </h3>
              <div className='row p-4'>
                <img src={takeaway} alt='notfound' className='h-55 mx-auto' />
              </div>
                <p className='col-10 mx-auto'>
                  We have adapted
                  our menu
                  so you can enjoy your favourites at home
                </p>
                <Link to="/menu" type="submit" className="menu-btn">Menu</Link>
                {/* <a to="/menu" onClick={menuClick} type="submit" className="menu-btn">Menu</a> */}
            </div>
        </div>
      </div>

        <div className='row border-bottom-after removeborder mt-40'>

          <div className='col-lg-6 col-md-6 col-12'>
            <h3 className='pb-2 foodtitle'>
              MORNING WITH KHAMAN
            </h3>
            <div className='row'>
              <p className='col-lg-6 col-md-12 justify'>
                  Starting your morning with Khaman is a delightful way to embrace the flavors of Gujarat and infuse your day with a burst of culinary joy. The soft, spongy texture and the invigorating blend of spices in Khaman make it a perfect breakfast or brunch option. 
               &nbsp; &nbsp; &nbsp; The vibrant yellow hue of Khaman is reminiscent of the morning sun, and its warm, comforting aroma can lift your spirits from the moment it graces your plate. Pair it with a cup of steaming chai (tea) or fresh fruit juice, and you have a balanced and satisfying morning meal that awakens your taste buds.
              <br/> &nbsp; &nbsp; &nbsp; Khaman's versatility allows you to enjoy it in various ways. You can savor it with chutneys like tamarind or coriander-mint, or even use it as a base for a sandwich or roll. Its light yet filling nature makes it an ideal choice for those looking for a wholesome and flavorful breakfast option.</p>
              <div className='col-lg-6 col-md-12'>
                <img src={khaman} alt='notfound' className='w-full image-border khaman' />
                <p className='justify'><br/>Incorporating Khaman into your morning routine is not just about nourishing your body; it's about starting your day with a taste of tradition and the irresistible charm of Gujarati cuisine, setting a positive tone for the hours ahead.</p>
              </div>
            </div>
          </div>

          <div className='col-lg-6 col-md-6 col-12'>
            <h3 className='pb-2 foodtitle'>
              BARODA'S SEV USAL
            </h3>
            <div className='row'>
              <p className='col-lg-6 col-md-12  justify'>
              Baroda's Sev Usal is a beloved culinary gem from the vibrant city of Vadodara (formerly known as Baroda) in the Indian state of Gujarat. This flavorful street food delight is a testament to the region's rich gastronomic heritage.
              <br/> &nbsp; &nbsp; &nbsp;Sev Usal features a hearty combination of soft, mashed white peas served with a medley of spices, chutneys, and garnishes. The dish is renowned for its robust flavors, which blend sweetness, tanginess, and spiciness in perfect harmony. A generous sprinkle of sev (crispy fried gram flour noodles) on top adds a delightful crunch that contrasts with the creamy texture of the peas.
              <br/> &nbsp; &nbsp; &nbsp; This mouthwatering dish is more than just a meal; it's a cultural experience that captures the essence of Vadodara's culinary traditions and the warmth of its people, making it a gastronomic treasure that continues to delight food enthusiasts from all corners of the globe.
              </p>
              <div className='col-lg-6 col-md-12 khaman'>
              <img src={sevUsal} alt='notfound' className='w-full image-border khaman' />
              <p className='justify'><br/>What makes Baroda's Sev Usal truly special is its ability to cater to diverse palates. It can be customized to suit individual spice preferences, making it a beloved comfort food for locals and a must-try for visitors exploring Vadodara's vibrant street food scene.</p>
              </div>
            </div>
          </div>   
        </div>     
      </div>

        {/* <header>
        <nav>
          <div class="logo">Logo</div>
          <ul class="nav-links">
            <Link to="/">Home</Link>
            <Link to="/about">About Us</Link>
            <Link to="/about">Services</Link>
            <Link to="/contact">Contact</Link>
          </ul>
        </nav>
        </header> */}


        {/* <div>
          <div className=''>Food Items</div>
        <div className={classes.foodItems}>Food Items</div>
        <div className={classes.popularDishes}>Popular Dishes</div>
        <div className={classes.image_Dishes_1}></div>
        <div className={classes.image_Dishes_2}></div>
        <div className={classes.image_Dishes_3}></div>
        <div className={classes.image_Dishes_4}></div>
        <div className={classes.image_Dishes_5}></div>
        <div className={classes.image_richHealthy_1}></div>
        <div className={classes.image_richHealthy_2}></div>
        <div className={classes.simpleAndEasyToDistinguishPlea}>
        </div>
        </div> */}
     
    </div>
  );
}

export default Home;
