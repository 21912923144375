import React, { useEffect } from 'react';
import Jalebi from './assets/Jalebi.jpg'
import fafdaJalebi from './assets/fafda-Jalebi.jpg'
import khaman from './assets/Khaman.png'
import homeeventicon from './assets/homeeventicon.png'
import officeeventicon from './assets/officeeventicon.png'
import partiesicon from './assets/partiesicon.png'
import takeaway from './assets/takeaway.png'
import hand from './assets/hand.png'
import mask from './assets/mask.png'
import plates from './assets/plates.png'
import sevUsal from './assets/Sevusal.jpeg'
import shop from './assets/mojshop.jpg'

const About = () => {
  useEffect(() => {
    window.scrollTo({
     top: 0,
     behavior: 'smooth' // This adds smooth scrolling animation (optional)
 });
}, []);

    return (<div>
     <div className='container'>

        <div className='pt-4 row border-bottom-after'>
          {/* <div className=''> */}
            {/* <h3 className='pb-4 foodtitle'>
              
            </h3> */}
            {/* <div className='row'> */}
              <p className='col-lg-6 col-md-6 col-12 justify'>
              Welcome to our Indian Vegetarian Restaurant, where the rich tapestry of Indian flavors and the goodness of plant-based cuisine come together to create an unforgettable dining experience.
              <br/> &nbsp; &nbsp; &nbsp; At our restaurant, we take pride in offering a diverse menu inspired by the culinary traditions of India. Our chefs are passionate about crafting dishes that celebrate the vibrant array of vegetables, legumes, and grains found in Indian cuisine. From the iconic classics like Chana Masala and Vegetable Biryani to regional specialties like South Indian Dosas and Gujarati Dhokla, every dish is a symphony of flavors, colors, and aromas.
              <br/> &nbsp; &nbsp; &nbsp; We believe that food is not just sustenance; it's a journey that connects people, cultures, and traditions. Our restaurant embodies this philosophy, providing a warm and welcoming ambiance where you can savor the essence of India's vegetarian culinary heritage. Whether you're a lifelong vegetarian or simply exploring the world of meat-free dining, our menu has something to delight every palate.
              <br/> &nbsp; &nbsp; &nbsp; Our commitment to quality extends beyond the kitchen. We source the freshest ingredients, and our talented chefs employ time-honored cooking techniques to create dishes that are not only delicious but also nourishing. We also cater to dietary preferences and allergies, ensuring that every guest can enjoy a memorable meal.</p>

              <div className='col-lg-6 col-md-6 col-12'>
                <img src={shop} alt='notfound' className='w-full image-border khaman' />
                <p className='justify'><br/>As you step into our restaurant, you become a part of our culinary journey. We invite you to indulge in the flavors of India, to share in our passion for vegetarian cuisine, and to create cherished memories with family and friends. Thank you for choosing us as your destination for authentic Indian vegetarian dining. We look forward to serving you and sharing the magic of Indian flavors with you.</p>
              </div>
            </div>
          {/* </div> */}
           
       {/* </div> */}
      

     
       
      </div>

    </div>
        
    
    )
}
  
export default About;