import React, { useState } from "react";
import GanpatiLogo from "../assets/GanpatiLogo.png"
import logo from "../assets/favicon.png"
import mmr from "../assets/mmrfinal.png"
import Border from "../assets/Border.png"
import '../index.css';
import arrowdown from "../assets/arrowdown.svg"
// import { FaArrowDown } from 'react-icons/fa';
import elephant from "../assets/elephant.png"
// import mojmasalarectangle from "./assets/mojmasalarectangle.png"
import { FaClock, FaFacebookSquare, FaInstagramSquare } from 'react-icons/fa';


const TopHeader = (props) => {



    const handleClick = () => {
        const windowHeight = window.innerHeight; // Height of the viewport
        window.scrollTo({ top: windowHeight, behavior: 'smooth' });
    };

    return (

        <div className='c-splash-div text-center'>
            <img src={Border} alt='notfound' className='right_border' />
            <img src={Border} alt='notfound' className='left_border' />
            <img src={elephant} alt='elephant' className='elephant elephant_left'/>
            <img src={elephant} alt='elephant' className='elephant elephant_right'/>
                
            <img src={GanpatiLogo} alt='notfound' className='logo-image' />
            <h3>    સ્વાગત છે </h3>
            <img className='mmrlogo' src={mmr} alt='mmrnotfound' />
            <div className='welcome'>
                <h3>મોજે મોજ રોજે રોજ</h3>
                <h3>WELCOME</h3>

                <a onClick={handleClick}>
                    <img src={arrowdown} alt="notfound" className='arrowdown' />
                </a>
            </div>
        </div>

    )
}

export default TopHeader;
