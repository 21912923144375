import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from "../assets/mmrfinal.png"
import TopHeader from './topheader';
import { useLocation } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';

const Header = (props) => {

    const location = useLocation();
    const currentURL = location.pathname;

    const [sectionIdVisible, setSectionIdVisible] = useState(true);
    const [menu, setMenu] = useState(false);

    const handleScroll = () => {
      if (window.scrollY >= window.innerHeight) {
        setSectionIdVisible(false);
      } else {
        setSectionIdVisible(true);
      }
    };
  
    useEffect(() => {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

    const handleClick = () => {
        setMenu(!menu);
    };

    return (
        <div className={ sectionIdVisible  ? "" : "add-class"}>
           { (currentURL == '/') ? <TopHeader /> : '' }
                    
            <div className='position-fixed-header'>
              <div className="top-header" id="target-section">
                    <img src={logo} alt='notfound' className='top-bannerlogo' />
                    <div className='top_header_right'>
                        <header class="header" >
                            <nav class="header__menu desktop_menu">
                                <ul>
                                    <li className={ (currentURL == '/') ? 'active' : '' } > <Link to="/">Home</Link></li>
                                    <li className={ (currentURL == '/about') ? 'active' : '' } > <Link to="/about">About</Link></li>
                                    <li className={ (currentURL == '/menu') ? 'active' : '' }> <Link to="/menu">Menu</Link></li>
                                    <li className={ (currentURL == '/contact') ? 'active' : '' }> <Link to="/contact">Contact</Link></li>
                                </ul>
                            </nav>
                        </header>
                    </div>
                    <div className='mobile_menu faBar' onClick={handleClick}>
                        <FaBars />
                    </div>
                </div>
                <div className="top-header-border"><hr className="border-t-2 border-b" /></div>
                { (menu == true) ? 
               
                   
                        <header className="header" >
                            <nav className="header__menu">
                                <ul>
                                    <li onClick={handleClick}  className={ (currentURL == '/') ? 'active' : '' } > <Link to="/">Home</Link></li>
                                    <li onClick={handleClick}  className={ (currentURL == '/about') ? 'active' : '' } > <Link to="/about">About</Link></li>
                                    <li onClick={handleClick}  className={ (currentURL == '/menu') ? 'active' : '' }> <Link to="/menu">Menu</Link></li>
                                    <li onClick={handleClick}  className={ (currentURL == '/contact') ? 'active' : '' }> <Link to="/contact">Contact</Link></li>
                                </ul>
                            </nav>
                        </header>
           
              
                     : '' }

               
            </div>
           
        </div>
    )

}
export default Header
