
import React, { useState } from 'react';
import { BrowserRouter as Router, Route,Routes } from 'react-router-dom';
import Home from './Home';
import Contact from './contact';
import About from './about';
import Header from './pages/header';
import TopHeader from './pages/topheader';
import Footer from './pages/footer';
import Menu from './pages/menu';

const App = () => {

  // componentDidMount = () => {
  //   this.getPost(this.props.match.params.slug);
  //   console.log(this.props.match.params.slug)
  // }

  // componentDidUpdate(prevProps) {
  //   if (prevProps.match.params.slug !== this.props.match.params.slug) {
  //     this.getPost(this.props.match.params.slug);
  //   }
  // }


  return (
    <Router>
      {/* <Home/> */}
      {/* <Contact/> */}
    <div>
      <Header/>
  
       <Routes>
                <Route exact path='/' element={< Home />}></Route>
                <Route exact path='/about' element={< About />}></Route> 
                <Route exact path='/menu' element={< Menu />}></Route> 
                <Route exact path='/contact' element={< Contact />}></Route>
        </Routes>
        <Footer/>
        </div>
    {/* <Route path="/" exact component={Home} /> */}
    {/* <Route path="/about" component={About} /> */}
    {/* <Route path="/contact" component={Contact} /> */}
  </Router>
  );
};

export default App;
